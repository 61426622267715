import React from 'react';
import PropTypes from 'prop-types';
import Hero from '@fuww/library/src/Hero';
import {
  DesktopOnlyContainer as DefaultDesktopOnlyContainer,
  MobileOnlyContainer as DefaultMobileOnlyContainer,
}
from '@fuww/library/src/ImageContainer';
import breakpoints from '@fuww/library/src/Theme/breakpoints';
import ImageWithUrls from '../ImageWithUrls';

export const defaultHeader = {
  default: {
    mobile: [
      'https://r.fashionunited.com/jhjxXmO3bMc2CoFC1XYa4AbtkBHT4TrS3HT7YAI4pi4/resize:fill:42:34:0/gravity:ce/quality:10/aHR0cHM6Ly9mYXNoaW9udW5pdGVkLmNvbS9pbWcvdXBsb2FkLzIwMjQvMDUvMDYvMS0wbDlyNmo4bi0yMDI0LTA1LTA2LnBuZw',
      'https://r.fashionunited.com/8rBeuI3mmfqSJGKufvts-qVyhbxx1ODj7jsi42TJiJM/resize:fill:360:288:0/gravity:ce/quality:70/aHR0cHM6Ly9mYXNoaW9udW5pdGVkLmNvbS9pbWcvdXBsb2FkLzIwMjQvMDUvMDYvMS0wbDlyNmo4bi0yMDI0LTA1LTA2LnBuZw',
      'https://r.fashionunited.com/VQYzjWzjnen4Udt2-UE6eAcr25Vm5KhHI1khq6aPQZ8/resize:fill:720:576:0/gravity:ce/quality:70/aHR0cHM6Ly9mYXNoaW9udW5pdGVkLmNvbS9pbWcvdXBsb2FkLzIwMjQvMDUvMDYvMS0wbDlyNmo4bi0yMDI0LTA1LTA2LnBuZw',
    ],
    desktop: [
      'https://r.fashionunited.com/1xaLnJDwOaAaA6-RwQu_NJkeb9yF625xmVHt3yKwUPA/resize:fill:42:8:0/gravity:ce/quality:10/aHR0cHM6Ly9mYXNoaW9udW5pdGVkLmNvbS9pbWcvdXBsb2FkLzIwMjQvMDUvMDYvaGVyby1pbWFnZS12eWQ1ZTFiby0yMDI0LTA1LTA2LnBuZw',
      'https://r.fashionunited.com/g3HSv9FT2YJoy1FI7ZnDktXrfBw0vf-72sPupWx3ECE/resize:fill:1350:277:0/gravity:ce/quality:70/aHR0cHM6Ly9mYXNoaW9udW5pdGVkLmNvbS9pbWcvdXBsb2FkLzIwMjQvMDUvMDYvaGVyby1pbWFnZS12eWQ1ZTFiby0yMDI0LTA1LTA2LnBuZw',
      'https://r.fashionunited.com/VsXf8X71oP5_v4djaNexcyvjN0yr2cahM2fSo9V_-NM/resize:fill:2560:526:0/gravity:ce/quality:70/aHR0cHM6Ly9mYXNoaW9udW5pdGVkLmNvbS9pbWcvdXBsb2FkLzIwMjQvMDUvMDYvaGVyby1pbWFnZS12eWQ1ZTFiby0yMDI0LTA1LTA2LnBuZw',
      'https://r.fashionunited.com/B625yjqqmdWogXKHXo4iFHuka7QINyh2HH5ieaUTOqE/resize:fill:5120:1052:0/gravity:ce/quality:70/aHR0cHM6Ly9mYXNoaW9udW5pdGVkLmNvbS9pbWcvdXBsb2FkLzIwMjQvMDUvMDYvaGVyby1pbWFnZS12eWQ1ZTFiby0yMDI0LTA1LTA2LnBuZw',
    ],
  },
  premium: {
    mobile: [
      'https://r.fashionunited.com/jhjxXmO3bMc2CoFC1XYa4AbtkBHT4TrS3HT7YAI4pi4/resize:fill:42:34:0/gravity:ce/quality:10/aHR0cHM6Ly9mYXNoaW9udW5pdGVkLmNvbS9pbWcvdXBsb2FkLzIwMjQvMDUvMDYvMS0wbDlyNmo4bi0yMDI0LTA1LTA2LnBuZw',
      'https://r.fashionunited.com/8rBeuI3mmfqSJGKufvts-qVyhbxx1ODj7jsi42TJiJM/resize:fill:360:288:0/gravity:ce/quality:70/aHR0cHM6Ly9mYXNoaW9udW5pdGVkLmNvbS9pbWcvdXBsb2FkLzIwMjQvMDUvMDYvMS0wbDlyNmo4bi0yMDI0LTA1LTA2LnBuZw',
      'https://r.fashionunited.com/VQYzjWzjnen4Udt2-UE6eAcr25Vm5KhHI1khq6aPQZ8/resize:fill:720:576:0/gravity:ce/quality:70/aHR0cHM6Ly9mYXNoaW9udW5pdGVkLmNvbS9pbWcvdXBsb2FkLzIwMjQvMDUvMDYvMS0wbDlyNmo4bi0yMDI0LTA1LTA2LnBuZw',
    ],
    desktop: [
      'https://r.fashionunited.com/8xiZlayB5MYVhVf4GEKQ8VZ2elCwzxRjnmAgYACQ57A/resize:fill:42:8:0/gravity:ce/quality:10/aHR0cHM6Ly9tZWRpYS5mYXNoaW9udW5pdGVkLmNvbS9sb2dvcy9jb21wYW55X3Byb2ZpbGVfcHJlbWl1bV9kZWZhdWx0Xy5ldE1KUk1uM3A2a25GS2RMai5qcGc',
      'https://r.fashionunited.com/RNBVOpKoxnoS8dM2w9_fvuNZIP9HccoAy9o384MWuww/resize:fill:1350:277:0/gravity:ce/quality:70/aHR0cHM6Ly9tZWRpYS5mYXNoaW9udW5pdGVkLmNvbS9sb2dvcy9jb21wYW55X3Byb2ZpbGVfcHJlbWl1bV9kZWZhdWx0Xy5ldE1KUk1uM3A2a25GS2RMai5qcGc',
      'https://r.fashionunited.com/dA8t6V2SLFkSDu1uZdFGhbF-APoXKYF7Tnbb8d8X2DQ/resize:fill:2560:526:0/gravity:ce/quality:70/aHR0cHM6Ly9tZWRpYS5mYXNoaW9udW5pdGVkLmNvbS9sb2dvcy9jb21wYW55X3Byb2ZpbGVfcHJlbWl1bV9kZWZhdWx0Xy5ldE1KUk1uM3A2a25GS2RMai5qcGc',
      'https://r.fashionunited.com/aB2RWacYI4tLo1drexj9fgQhlTkmMGwF93xGRlb47LU/resize:fill:5120:1052:0/gravity:ce/quality:70/aHR0cHM6Ly9tZWRpYS5mYXNoaW9udW5pdGVkLmNvbS9sb2dvcy9jb21wYW55X3Byb2ZpbGVfcHJlbWl1bV9kZWZhdWx0Xy5ldE1KUk1uM3A2a25GS2RMai5qcGc',
    ],
  },
};

export const MobileOnlyImage = ({ title, imageUrls, MobileOnlyContainer }) => (
  <MobileOnlyContainer>
    <ImageWithUrls
      alt={title}
      sizes="100vw"
      imageUrls={imageUrls}
      srcSet={`${imageUrls[1]
      } 360w, ${imageUrls[2]
      } 720w`}
      preload
      preloadMediaQuery={`(max-width: ${breakpoints.sm}px)`}
      loadStrategy="instant"
      finalFetchpriority="high"
      placeholderFetchpriority="high"
    />
  </MobileOnlyContainer>
);

MobileOnlyImage.propTypes = {
  title: PropTypes.string,
  imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  MobileOnlyContainer: PropTypes.elementType,
};

MobileOnlyImage.defaultProps = {
  title: null,
  MobileOnlyContainer: DefaultMobileOnlyContainer,
};

export const DesktopOnlyImage = ({
  title, imageUrls, DesktopOnlyContainer, disableRoundedCorners,
}) => (
  <DesktopOnlyContainer>
    <ImageWithUrls
      alt={title}
      sizes="100vw"
      imageUrls={imageUrls}
      srcSet={`${imageUrls[1]
      } 1350w, ${imageUrls[2]
      } 2560w, ${imageUrls[3]
      } 5120w`}
      preload
      preloadMediaQuery={`(min-width: ${breakpoints.sm}px)`}
      loadStrategy="on-visible"
      finalFetchpriority="high"
      placeholderFetchpriority="high"
      disableRoundedCorners={disableRoundedCorners}
    />
  </DesktopOnlyContainer>
);

DesktopOnlyImage.propTypes = {
  title: PropTypes.string,
  imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  DesktopOnlyContainer: PropTypes.elementType,
  disableRoundedCorners: PropTypes.bool,
};

DesktopOnlyImage.defaultProps = {
  title: null,
  DesktopOnlyContainer: DefaultDesktopOnlyContainer,
  disableRoundedCorners: false,
};

const HeaderImage = ({
  profile,
  MobileOnlyContainer,
  DesktopOnlyContainer,
}) => {
  const {
    page: {
      premium,
      imageUrls,
      mobileImageUrls,
      title,
    },
  } = profile;

  const commonImageUrls = premium
    ? defaultHeader.premium
    : defaultHeader.default;

  const mobileHeaderUrlsWithFallback = mobileImageUrls[0]
    ? mobileImageUrls
    : imageUrls.slice(4, 7);

  const mobileHeaderUrls = premium && mobileHeaderUrlsWithFallback[0]
    ? mobileHeaderUrlsWithFallback
    : commonImageUrls.mobile;

  const desktopHeaderUrls = premium && imageUrls[0]
    ? imageUrls.slice(0, 4)
    : commonImageUrls.desktop;

  const imageTitle = `Company Profile header ${title}`;

  return (
    <Hero
      image={(
        <>
          <MobileOnlyImage
            title={imageTitle}
            imageUrls={mobileHeaderUrls}
            MobileOnlyContainer={MobileOnlyContainer}
          />
          <DesktopOnlyImage
            title={imageTitle}
            imageUrls={desktopHeaderUrls}
            DesktopOnlyContainer={DesktopOnlyContainer}
            disableRoundedCorners
          />
        </>
      )}
    />
  );
};

HeaderImage.propTypes = {
  profile: PropTypes.shape({
    page: PropTypes.shape({
      premium: PropTypes.bool,
      imageUrls: PropTypes.arrayOf(PropTypes.string),
      mobileImageUrls: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
    }),
  }).isRequired,
  MobileOnlyContainer: PropTypes.elementType,
  DesktopOnlyContainer: PropTypes.elementType,
};

HeaderImage.defaultProps = {
  MobileOnlyContainer: DefaultMobileOnlyContainer,
  DesktopOnlyContainer: DefaultDesktopOnlyContainer,
};

export default HeaderImage;
