import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Figure = styled.figure`
  margin: 0;
  display: flex;

  /* stylelint-disable-next-line selector-type-no-unknown */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: none;
  }
`;

const Hero = ({
  image,
  className,
}) => (
  <Figure className={className}>
    {image}
  </Figure>
);

Hero.defaultProps = {
  image: undefined,
  className: undefined,
};

Hero.propTypes = {
  /** Sets the image */
  image: PropTypes.node,
  /** Sets the class name */
  className: PropTypes.string,
};

export default Hero;
