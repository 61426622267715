import styled from '@emotion/styled';

const MobileOnlyContainer = styled.div`
  width: 100vw;
  height: 80vw;

  /* stylelint-disable-next-line selector-type-no-unknown */
  && progressive-img, img {
    width: 100vw;
    height: 80vw;
    --img-width: 100vw;
    --img-height: 80vw;
    object-fit: cover;
  }

  @media (min-width: 600px) {
    display: none;
  }
`;

const DesktopOnlyContainer = styled.div`
  display: none;

  @media (min-width: 600px) {
    display: block;
    width: 100vw;
    height: 20vw;

    /* stylelint-disable-next-line selector-type-no-unknown */
    && progressive-img, img {
      width: 100vw;
      height: 20vw;
      --img-width: 100vw;
      --img-height: 20vw;
      object-fit: cover;
    }
  }
`;

const JobsMobileOnlyContainer = styled.div`
  width: 100vw;
  height: 80vw;

  /* stylelint-disable-next-line selector-type-no-unknown */
  && progressive-img, img {
    width: 100vw;
    height: 80vw;
    --img-width: 100vw;
    --img-height: 80vw;
    object-fit: cover;
  }

  @media (min-width: 600px) {
    display: none;
  }
`;

const JobsDesktopOnlyContainer = styled.div`
  display: none;
  width: 100vw;
  height: 20vw;

  @media (min-width: 600px) {
    display: block;
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  && progressive-img, img {
    width: 100vw;
    height: 20vw;
    --img-width: 100vw;
    --img-height: 20vw;
    object-fit: cover;
  }
`;

export {
  DesktopOnlyContainer,
  MobileOnlyContainer,
  JobsMobileOnlyContainer,
  JobsDesktopOnlyContainer,
};
